const generation1Array = []
const generation2Array = []
const generation3Array = []
const generation4Array = []
const generation5Array = []
const generation6Array = []
const generation7Array = []
const generation8Array = []

for (let i = 1; i <= 151; i++){
  generation1Array.push(i)
}
for (let i = 152; i <= 251; i++){
  generation2Array.push(i)
}
for (let i = 252; i <= 386; i++){
  generation3Array.push(i)
}
for (let i = 387; i <= 493; i++){
  generation4Array.push(i)
}
for (let i = 494; i <= 649; i++){
  generation5Array.push(i)
}
for (let i = 650; i <= 721; i++){
  generation6Array.push(i)
}
for (let i = 722; i <= 809; i++){
  generation7Array.push(i)
}
for (let i = 810; i <= 893; i++){
  generation8Array.push(i)
}

export{generation1Array, generation2Array, generation3Array, generation4Array, generation5Array, generation6Array, generation7Array, generation8Array}